/* eslint-disable camelcase */
import { decrypt } from '../Middleware/helpers';
import { getPlatform } from '../Utils';
import { getCookies } from './Cookies';

const isClientSide = typeof window === 'object';

let email = null;
let contact = null;

let razorpay;

export const RZPInitialization = ({ params, notes, redirect = true, dkt_sessionId } = {}) => {
  if (!isClientSide) return false;

  const platform = getPlatform();
  const {
    de_em: appEmail,
    de_nm: appNumber,
    dkt_em: webEmail,
    dkt_mn: webNumber,
    dkt_cartId: cartId,
    access_token: appAccessToken,
    cartTotal,
    AMIDKTLoginDecathlonApp,
    v4CartVersion,
  } = getCookies();

  if (platform === 'APP') {
    email = appEmail || '';
    contact = appNumber || '';
  } else {
    email = decrypt(webEmail) || '';
    contact = decrypt(webNumber) || '';
  }

  contact = contact?.includes('+91') ? contact?.replace('+91', '') : contact;

  let callbackURL = `${window.location.origin}/checkout`;

  if (window.innerWidth < 768 && platform !== 'PHONEPE_SWITCH') {
    callbackURL = `${window.location.origin}/payment`;
  }

  let appApiVersion = '';

  if (platform === 'APP') {
    appApiVersion = `&api_version=${AMIDKTLoginDecathlonApp ? 'AMI-DKT' : v4CartVersion ? 'NEBULA' : 'AMI-NEBULA'}`;
  }

  let defaultParams = `?dkt_ci=${cartId}&dkt_ct=${cartTotal}&dkt_pf=${platform}${appApiVersion}`;

  if (platform === 'APP') {
    defaultParams = `${defaultParams}&access_token=${encodeURIComponent(
      appAccessToken,
    )}&dkt_em=${email}&dkt_mn=${contact}`;
  } else {
    defaultParams = `${defaultParams}&dkt_sessionId=${dkt_sessionId}`;
  }

  razorpay = new window.Razorpay({
    key: process.env.RAZORPAY_KEY,
    currency: 'INR',
    name: 'DECATHLON',
    redirect,
    callback_url: `${callbackURL}${defaultParams}${params || ''}`,
    prefill: {
      email,
      contact,
    },
    notes,
    theme: {
      color: '#0082C3',
    },
  });

  return razorpay;
};

export const RZPCheckForGooglePayIntent = () => {
  if (!isClientSide) return false;
  if (!razorpay) razorpay = RZPInitialization();

  return new Promise((resolve, _reject) => {
    razorpay
      .checkPaymentAdapter('gpay')
      .then(() => resolve(true))
      .catch(() => resolve(false));
  });
};

export const RZPCheckForCredEligibility = () => {
  if (!isClientSide) return false;
  if (!razorpay) razorpay = RZPInitialization();

  return new Promise((resolve, _reject) => {
    razorpay
      .checkCREDEligibility(`+91${contact}`)
      .then((res) =>
        resolve({
          status: true,
          description: res?.data?.offer?.description || 'Pay using your saved credit cards for instant checkout',
        }),
      )
      .catch((res) =>
        resolve({
          status: false,
          description: res?.error?.description || 'Pay using your saved credit cards for instant checkout',
        }),
      );
  });
};

export const RZPCreatePaymentForGooglePayIntent = async (options) => {
  if (!isClientSide) return false;
  if (!razorpay) razorpay = RZPInitialization();

  const googlePayStatus = await RZPCheckForGooglePayIntent();

  return new Promise((resolve, _reject) => {
    if (googlePayStatus) {
      razorpay.createPayment(
        {
          email,
          contact,
          method: 'upi',
          ...options,
        },
        { gpay: true },
      );
    } else {
      resolve({
        status: false,
        description: 'Google Pay is not working, please try after some time.',
      });
    }

    razorpay.on('payment.success', () =>
      resolve({
        status: true,
      }),
    );

    razorpay.on('payment.error', (response) =>
      resolve({
        status: false,
        description: response?.error?.description || 'Google Pay is not working, please try after some time.',
      }),
    );
  });
};

export const RZPCreatePayment = async ({ decathlonOrderId, decathlonNotes, ...rest }) => {
  if (!isClientSide) return false;
  if (!razorpay)
    razorpay = RZPInitialization({
      params: `&dkt_oi=${decathlonOrderId}`,
      notes: decathlonNotes,
    });

  return new Promise((resolve, _reject) => {
    razorpay.createPayment({
      email,
      contact,
      ...rest,
    });

    resolve(true);
  });
};
