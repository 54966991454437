/* eslint-disable no-else-return */
/* eslint-disable no-console */
/* eslint-disable no-return-await */
import fetchApi from '@/src/Middleware/fetchApi';
import { getPlatform } from '@/src/Utils';
import { redirectToSignInPage } from '@/src/Utils/DKTLogin';
import { getCookie, getCookies, setCookie } from '../../src/Utils/Cookies';
import { RZPCreatePayment, RZPInitialization } from '../../src/Utils/Razorpay';

const getDeliveryAddress = ({ ctx, sessionId } = {}) => {
  return async (dispatch) => {
    dispatch({
      type: 'TOGGLE_ADDRESS_LOADER',
      payload: true,
    });

    const apiResponse = await fetchApi(ctx, `/api/checkout/delivery-address`, {
      cookies: sessionId ? `dkt_sessionId=${sessionId}` : false,
    });

    if (apiResponse?.status) {
      dispatch({
        type: 'GET_DELIVERY_ADDRESS',
        payload: apiResponse?.addressList,
      });
    }

    dispatch({
      type: 'TOGGLE_ADDRESS_LOADER',
      payload: false,
    });

    return apiResponse;
  };
};

const addDeliveryAddress = ({ shippingAddress, billingAddress }) => {
  return async (dispatch) => {
    dispatch({
      type: 'TOGGLE_ADD_ADDRESS_LOADER',
      payload: true,
    });

    const apiResponse = await fetchApi(null, `/api/checkout/add-address`, {
      data: {
        shippingAddress,
        billingAddress,
      },
    });

    if (apiResponse?.status) {
      dispatch({
        type: 'UPDATE_SELECTED_ADDRESS_IDS',
        payload: { shippingAddress, billingAddress },
      });
    }

    dispatch({
      type: 'TOGGLE_ADD_ADDRESS_LOADER',
      payload: false,
    });

    return apiResponse;
  };
};

const getDeliveryOptions = () => {
  return async (dispatch) => {
    const apiResponse = await fetchApi(null, `/api/checkout/delivery-options`, {
      retry: 1,
      retryConditionally: (res) => res?.statusCode >= 500 || res?.statusCode === 404,
    });

    let payload = apiResponse?.data || {};

    if (apiResponse?.retried && !payload?.fulfillmentOptions) {
      payload = {
        status: true,
        errorDetails: null,
        fulfillmentOptions: {
          standardHomeDelivery: [
            {
              shippingCost: 229,
              homeDeliveryHeader: '10 Business days',
              shipment: [
                {
                  orderPart: [],
                  postalCode: 560064,
                  fulfiller: '1058',
                  fulfillerType: 'Store',
                  orderType: 'STORE_HOME_DELIVERY',
                  deliveryServices: {
                    carrierId: 25,
                    sapCarrierId: 63187,
                    deliveryPartner: 'Blowhorn Air',
                    serviceType: 'Standard',
                    shippingCost: 229,
                    timeForDeliveryHours: 240,
                    timeForDelivery: '10 Business days',
                    timeForDeliveryDate: '10 Business days',
                    postPurchaseService: 'NONE',
                    cutOffTime: '15:00:00',
                    cutoffTimeCountdown: '240',
                    zonedCutoffTime: '2020-10-07T15:00+05:30[Asia/Kolkata]',
                    timeForDeliveryPreText: 'By ',
                    fulfillmentOptionId: 'DRP',
                  },
                },
              ],
            },
          ],
          fulfillmentOptionsConfigurations: {
            sortPriority: {
              standardHomeDelivery: 1,
            },
            fulfillmentOptionSubHeader: null,
            fulfillmentOptionHeader: {
              standardHomeDelivery: 'Standard Home Delivery',
            },
            convenienceAvailability: {
              standardHomeDelivery: {
                enabled: true,
                errorMessage:
                  "We're sorry, some product(s) in your cart are only available at a store and cannot be home delivered at the moment",
              },
            },
          },
        },
      };
    }

    dispatch({
      type: 'GET_DELIVERY_OPTIONS',
      payload,
    });

    dispatch({
      type: 'GET_CONFIGURATIONS_OPTIONS',
      payload: payload?.fulfillmentOptions?.fulfillmentOptionsConfigurations || {},
    });

    return apiResponse;
  };
};

const updateCartTotal = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: 'UPDATE_CART_TOTAL',
      payload,
    });
  };
};

const setAnalyticsData = ({
  method = 'unknown',
  giftCards = [],
  orderId,
  paymentMethod,
  cartItems,
  shippingAddress,
  billingAddress,
  fulfillerId,
}) => {
  const platform = getPlatform();
  const { localStorage, sessionStorage } = window;
  // payment logs
  if (typeof sessionStorage !== 'undefined') {
    sessionStorage.removeItem('paymentFailedLogsPayment');
    sessionStorage.setItem(
      'paymentFailedLogsPayment',
      JSON.stringify({
        method,
        giftCards: giftCards.length > 0,
        orderId,
        paymentMethod,
        shippingAddress,
        billingAddress,
      }),
    );
  }

  setCookie('orderId', orderId, {
    expires: new Date(new Date().getTime() + 60 * 60 * 24 * 1000 * 2),
  });

  if (platform === 'APP') {
    setCookie('paymentMethod', paymentMethod, {
      expires: new Date(new Date().getTime() + 60 * 60 * 24 * 1000 * 2),
    });
  } else {
    localStorage.setItem(
      'orderData',
      JSON.stringify({
        method: paymentMethod,
        cartItems,
        shippingAddress,
        billingAddress,
        fulfillerId,
      }),
    );
    localStorage.setItem('checkoutReload', 'decathlon');
    localStorage.removeItem('shippingAddress');
    localStorage.removeItem('billingAddress');
    localStorage.removeItem('fulfillerId');
    sessionStorage.setItem('transaction', 'paymentdone');
  }
};
const addDeliveryOption = (splitFulfilments) => {
  return async (dispatch) => {
    dispatch({
      type: 'ADD_DELIVERY_OPTION',
      payload: true,
    });

    const apiResponse = await fetchApi(null, `/api/checkout/add-options`, {
      data: splitFulfilments,
    });

    dispatch({
      type: 'ADD_DELIVERY_OPTION',
      payload: !apiResponse?.status ? 'failed' : false,
    });

    return apiResponse;
  };
};

const paymentLoader = (payload) => {
  return (dispatch) => {
    dispatch({ type: 'PAYMENT_LOADER', payload });
  };
};

const getGoogleAnalyticId = ({ platform = null }) => {
  const { _gid, _ga, googleAnalyticId } = getCookies();

  if (platform === 'APP') {
    return googleAnalyticId || _gid || null;
  }

  return _ga ? _ga.split('.').splice(2).join('.') : null;
};

const VPAValidation = (vpa) => {
  return async (dispatch) => {
    return fetchApi(null, `/api/checkout/vpa-validation`, {
      data: {
        vpa,
      },
    });
  };
};

const paymentInitialization = () => {
  return async (dispatch) => {
    const platform = getPlatform();
    const { version, decathlonApp } = getCookies();

    const apiResponse = await fetchApi(null, `/api/checkout/payment-init`, {
      data: {
        channel: (platform === 'APP' && decathlonApp) || (platform === 'PAYTM_MINI' && 'PAYTM_MINI') || 'WEB',
        version: platform === 'APP' ? version : '2.0',
      },
    });

    if (apiResponse?.status) {
      dispatch({
        type: 'PAYMENT_INIT',
        payload: {
          razorpay: apiResponse?.payment?.razorpay,
          onepay: apiResponse?.payment?.onepay,
        },
      });

      await new Promise((resolve, reject) => {
        const razorpay = RZPInitialization();

        razorpay.once('ready', (response) => {
          dispatch({
            type: 'RAZORPAY_OPTIONS',
            payload: response.methods,
          });
          resolve();
        });
      });
    }

    return apiResponse;
  };
};

const paymentDowntime = () => {
  return async (dispatch) => {
    const platform = getPlatform();
    const { version, decathlonApp } = getCookies();

    const apiResponse = await fetchApi(null, `/api/checkout/payment-downtime`, {
      data: {
        channel: (platform === 'APP' && decathlonApp) || (platform === 'PAYTM_MINI' && 'PAYTM_MINI') || 'WEB',
        version: platform === 'APP' ? version : '2.0',
      },
    });

    if (apiResponse?.status) {
      dispatch({
        type: 'PAYMENT_DOWNTIME',
        payload: { downtime: apiResponse?.payment },
      });
    }
  };
};

const createPaymentBackend = ({ method, giftCards = [], cartTotalAmount = null } = false) => {
  return async (dispatch) => {
    if (cartTotalAmount !== null && Number(cartTotalAmount) <= 0 && giftCards.length <= 0) {
      return {
        status: false,
        message: 'To proceed, please ensure that your order value is greater than the voucher value',
      };
    }

    dispatch(checkoutPage.paymentLoader(true));
    const { ip, version, decathlonApp } = getCookies();
    const platform = getPlatform();

    const apiResponse = await fetchApi(null, `/api/checkout/create-payment`, {
      data: {
        method,
        giftCards,
        cartTotal: cartTotalAmount,
        version: platform === 'APP' ? version : '2.0',
        channel: (platform === 'APP' && decathlonApp) || (platform === 'PAYTM_MINI' && 'PAYTM_MINI') || 'WEB',
        _gid: getGoogleAnalyticId({ platform }),
        userAgent: platform === 'APP' ? decathlonApp : window.navigator.userAgent,
        ip,
      },
    });

    dispatch(checkoutPage.paymentLoader(false));

    return apiResponse;
  };
};

const getPaymentStatus = ({ orderId, ctx } = {}) => {
  return async (dispatch) => {
    const platform = getPlatform(ctx);
    const { version, decathlonApp } = getCookies({
      req: ctx?.req,
      res: ctx?.res,
    });
    dispatch({ type: 'PAYMENT_STATUS_LOADER', payload: true });

    const apiResponse = await fetchApi(null, `/api/checkout/payment-status`, {
      data: {
        orderId,
        channel: (platform === 'APP' && decathlonApp) || (platform === 'PAYTM_MINI' && 'PAYTM_MINI') || 'WEB',
        version: platform === 'APP' ? version : '2.0',
      },
    });

    dispatch({
      type: 'PAYMENT_STATUS',
      payload: apiResponse?.status ? apiResponse?.payment?.status : 'FAILED',
    });

    dispatch({ type: 'PAYMENT_STATUS_LOADER', payload: false });

    return apiResponse;
  };
};

const createPaymentRazorPay = ({
  method,
  customerId,
  cartItems,
  giftCards,
  paymentMethod,
  razorpayFields,
  cartTotalAmount = 0,
}) => {
  return async (dispatch) => {
    dispatch(checkoutPage.paymentLoader(true));

    const platform = getPlatform();

    if (platform !== 'APP' && (!getCookie('dkt_em') || !getCookie('dkt_mn'))) {
      redirectToSignInPage();
    }

    const apiResponse =
      (await dispatch(
        checkoutPage.createPaymentBackend({
          method,
          giftCards,
          cartTotalAmount,
        }),
      )) || {};

    if (!apiResponse?.status) {
      if (platform === 'APP') {
        if (apiResponse?.statusCode === 401) {
          return {
            paymentStatus: true,
            redirectUrl: '/thankyou?error=exit',
          };
        }
        return {
          paymentStatus: false,
          errorMessage: apiResponse?.message || process.env.NEXT_PUBLIC_ERROR_MESSAGE,
        };
      }

      return {
        paymentStatus: false,
        errorMessage: apiResponse?.message || process.env.NEXT_PUBLIC_ERROR_MESSAGE,
      };
    }

    if (platform === 'APP') {
      setCookie('orderId', apiResponse?.payment?.orderId, {
        expires: new Date(new Date().getTime() + 60 * 60 * 24 * 1000 * 2),
      });
      setCookie('paymentMethod', 'paymentMethod', {
        expires: new Date(new Date().getTime() + 60 * 60 * 24 * 1000 * 2),
      });
    }

    if (method === 'RAZORPAY_INTENT') {
      if (!apiResponse?.payment?.razorpay.razorpayIntentLink) {
        return {
          paymentStatus: false,
          errorMessage:
            'We are sorry, an unexpected error occurred while generating intent link. Please try again later.',
        };
      }

      return {
        paymentStatus: true,
        orderId: apiResponse?.payment?.orderId,
        deepLink: apiResponse?.payment?.razorpay.razorpayIntentLink,
      };
    }

    if (paymentMethod === 'Giftcard') {
      if (platform !== 'APP') {
        setAnalyticsData({
          method,
          giftCards,
          orderId: apiResponse?.payment?.orderId,
          paymentMethod,
          cartItems,
          shippingAddress: window.localStorage.getItem('shippingAddress'),
          billingAddress: window.localStorage.getItem('billingAddress'),
          fulfillerId: window.localStorage.getItem('fulfillerId'),
        });
      }
      return {
        paymentStatus: true,
        redirectUrl: '/thankyou',
      };
    }

    RZPInitialization({
      params: `&dkt_oi=${apiResponse?.payment?.orderId}`,
      dkt_sessionId: apiResponse?.payment?.dkt_sessionId,
      notes: apiResponse?.payment?.razorpay.notes,
    });

    await RZPCreatePayment({
      amount: Number(apiResponse?.payment?.razorpay.amount) * 100,
      order_id: apiResponse?.payment?.razorpay.orderId,
      customer_id: customerId,
      decathlonOrderId: apiResponse?.payment?.orderId,
      decathlonNotes: apiResponse?.payment?.razorpay.notes,
      ...razorpayFields,
    });

    setAnalyticsData({
      method,
      giftCards,
      orderId: apiResponse?.payment?.orderId,
      paymentMethod,
      cartItems,
      shippingAddress: window.localStorage.getItem('shippingAddress'),
      billingAddress: window.localStorage.getItem('billingAddress'),
      fulfillerId: window.localStorage.getItem('fulfillerId'),
    });

    dispatch(checkoutPage.paymentLoader(false));
    return {
      paymentStatus: true,
      redirectUrl: false,
    };
  };
};

const getINNSeriesValidate = (cardNumber) => {
  return async (dispatch) => {
    return fetchApi(null, `/api/checkout/bin-series`, {
      data: { cardNumber },
    });
  };
};

const getGiftCardDetail = (number, pin) => {
  return async (dispatch) => {
    dispatch({ type: 'PAYMENT_LOADER', payload: true });
    const { cartTotal } = getCookies();

    const apiResponse = await fetchApi(null, '/api/checkout/qwikcilver/validate', {
      data: {
        number,
        pin,
        amount: cartTotal,
      },
    });

    dispatch({ type: 'PAYMENT_LOADER', payload: false });

    return apiResponse;
  };
};

const addGiftCard = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: 'ADD_GIFTCARD',
      payload,
    });
  };
};

const removeGiftCard = (cardNumber) => {
  return async (dispatch) => {
    dispatch({
      type: 'REMOVE_GIFTCARD',
      payload: cardNumber,
    });
  };
};

const deactivateQRCode = (orderId) => {
  return async (dispatch) => {
    const { version, decathlonApp } = getCookies();
    const platform = getPlatform();
    dispatch(checkoutPage.paymentLoader(true));

    const apiResponse = await fetchApi(null, `/api/checkout/deactivate-qr-code`, {
      data: {
        orderId,
        channel: platform === 'APP' ? decathlonApp : 'WEB',
        version: version || '2.0',
      },
    });

    dispatch(checkoutPage.paymentLoader(false));

    return apiResponse;
  };
};

const googlePayAvailable = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: 'GOOGLE_PAY_AVAILABLE',
      payload,
    });
  };
};

const CREDEligibilityCheck = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: 'CRED_ELIGIBILITY_CHECK',
      payload,
    });
  };
};

const setCheckoutError = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_CHECKOUT_ERROR',
      payload,
    });
  };
};

const setAvailableIntentApps = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_AVAILABLE_INTENT_APPS',
      payload,
    });
  };
};

const checkoutPage = {
  getDeliveryAddress,
  addDeliveryAddress,
  getDeliveryOptions,
  addDeliveryOption,
  createPaymentBackend,
  getPaymentStatus,
  paymentInitialization,
  updateCartTotal,
  paymentLoader,
  createPaymentRazorPay,
  getGiftCardDetail,
  addGiftCard,
  removeGiftCard,
  deactivateQRCode,
  googlePayAvailable,
  setCheckoutError,
  setAvailableIntentApps,
  paymentDowntime,
  VPAValidation,
  getINNSeriesValidate,
  CREDEligibilityCheck,
};

export default checkoutPage;
