/* eslint-disable import/no-cycle */
import actions from './actions';
import LocationPrompt from './LocationPrompt';
import ProductPage from './ProductPage';
import login from './login';
import CartPage from './CartPage';
import CheckoutPage from './CheckoutPage';
import OrderTracking from './OrderTracking';
import MyAccountPage from './MyAccountPage';
// import CategoryPage from './CategoryPage';
import ShopPage from './ShopPage';
import StorePage from './StorePage';

export default {
  ...actions,
  ...LocationPrompt,
  ...ProductPage,
  ...CartPage,
  ...CheckoutPage,
  ...OrderTracking,
  ...login,
  ...MyAccountPage,
  // ...CategoryPage,
  ...ShopPage,
  ...StorePage,
};
