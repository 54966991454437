import axios from 'axios';

const errorHandler = (error, message) => {
  return {
    status: false,
    statusCode: error?.response?.status,
    message:
      error.message == 'Network Error'
        ? 'No internet connection make sure wifi or cellular data is turned on, then try again.'
        : message || error.message,
  };
};

const getStoreStats = ({ storeID, period, displays, authorTypes }) => {
  return async (dispatch) => {
    return await axios
      .get(
        `/api/store/store-reviews-stats?storeID=${storeID}&period=${period}&displays=${displays}&authorTypes=${authorTypes}`,
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return errorHandler(
          error,
          'Currently our service is not available at your area, Please check again after some time.',
        );
      });
  };
};

const storePage = {
  getStoreStats,
};

export default storePage;
