/* eslint-disable import/no-cycle */
import { pageContent } from '@/constant/contentfulConstants';
import * as wcmsOptimization from '@/src/wcmsOptimization';
import { getContentfulClientEntries } from '../../src/Utils';

export const getShopPageData = ({ ctx }) => {
  const { query, asPath, isServer } = ctx;

  return async (dispatch) => {
    dispatch({
      type: 'SET_SHOP_PAGE_DATA',
      payload: {
        shopPageLoader: true,
        show404Page: false,
      },
    });

    const pageSlug = query.shop.length > 1 ? query.shop[1] : query.shop[0];

    // opecoDataWCMS;
    const contentfulEntries = await getContentfulClientEntries({
      content_type: pageContent.type,
      include: 10,
      [pageContent.key]: pageSlug,
    });

    const show404Page = contentfulEntries?.items?.length <= 0;

    const { floorTypes } = wcmsOptimization;
    const deviceRestrictedFloors = [
      floorTypes.blogBanner,
      floorTypes.categoryCarousel,
      floorTypes.fourBanner,
      floorTypes.heroBanner,
      floorTypes.list,
      floorTypes.mainBanner,
      floorTypes.miniBanner,
      floorTypes.multipleBanner,
      floorTypes.persoReco,
      floorTypes.productCarousel,
      floorTypes.productCollage,
      floorTypes.productRecall,
      floorTypes.productScroll,
      floorTypes.promiseSection,
    ];

    let floorEntires = [];

    if (!show404Page && contentfulEntries?.items?.[0]?.fields?.floor?.length) {
      floorEntires = contentfulEntries?.items?.[0]?.fields?.floor
        ?.filter((floor) => {
          const floorType = floor?.sys?.contentType?.sys?.id;

          if (floorType === floorTypes.appFloor) {
            return true;
          }

          if (
            deviceRestrictedFloors.includes(floorType) &&
            contentfulEntries.device === 'PAYTM_MINI' &&
            floor.fields.excludeShopInShop &&
            typeof floor.fields.excludeShopInShop !== 'undefined'
          ) {
            return false;
          }

          if (floorType === floorTypes.categoryBanners) {
            return true;
          }

          if (floorType === floorTypes.content) {
            return true;
          }

          return (
            typeof floorType !== 'undefined' &&
            typeof floor.fields.display !== 'undefined' &&
            (floor.fields.display === 'webOnly' || floor.fields.display === 'webAndApp')
          );
        })
        ?.map((floor) => {
          const floorType = floor?.sys?.contentType?.sys?.id;

          switch (floorType) {
            case floorTypes.blogBanner:
              return wcmsOptimization.blogBanner(floor);
            case floorTypes.categoryCarousel:
              return wcmsOptimization.categoryCarousel(floor);
            case floorTypes.categoryDescription:
              return wcmsOptimization.categoryDescription(floor);
            case floorTypes.dualBannerCard:
              return wcmsOptimization.dualBannerCard(floor);
            case floorTypes.headingMainTitle:
              return wcmsOptimization.headingMainTitle(floor);
            case floorTypes.heroBanner:
              return wcmsOptimization.heroBanner(floor);
            case floorTypes.miniBanner:
              return wcmsOptimization.miniBanner(floor);
            case floorTypes.multipleBanner:
              return wcmsOptimization.multipleBanner(floor);
            case floorTypes.categoryBanners:
              return wcmsOptimization.CategoryBannersMapper(floor);
            case floorTypes.persoReco:
              return wcmsOptimization.persoReco(floor);
            case floorTypes.productCarousel:
              return wcmsOptimization.productCarousel(floor);
            case floorTypes.productCollage:
              return wcmsOptimization.productCollage(floor);
            case floorTypes.promiseSection:
              return wcmsOptimization.promiseSection(floor);
            case floorTypes.fourBanner:
              return wcmsOptimization.fourBanner(floor);
            case floorTypes.list:
              return wcmsOptimization.list(floor);
            case floorTypes.mainBanner:
              return wcmsOptimization.mainBanner(floor);
            case floorTypes.productScroll:
              return wcmsOptimization.productScroll(floor);
            case floorTypes.shopByCategory:
              return wcmsOptimization.shopByCategories(floor);
            case floorTypes.dualCardText:
              return wcmsOptimization.dualCardText(floor);
            case floorTypes.categoryBanners:
              return wcmsOptimization.CategoryBannersMapper(floor);
            case floorTypes.quickGuideFloor:
              return wcmsOptimization.quickGuideFloor(floor);
            case floorTypes.promiseV2:
              return wcmsOptimization.promiseV2(floor);
            case floorTypes.policyFaqs:
              return wcmsOptimization.policyFaqs(floor);
            case floorTypes.table:
              return wcmsOptimization.table(floor);
            case floorTypes.content:
              return wcmsOptimization.content(floor);
            default:
              return floor;
          }
        });
    }

    const wcmsEntry = contentfulEntries?.items[0]?.fields || {};

    dispatch({
      type: 'SET_SHOP_PAGE_DATA',
      payload: {
        shopPageLoader: show404Page,
        show404Page,
        shopPage: {
          meta: {
            title: wcmsEntry.metaTitle || 'Decathlon',
            description: wcmsEntry.metaDescription || 'Decathlon',
            pageDescription: wcmsEntry.pageDescription,
          },
          entries: floorEntires || [],
        },
      },
    });

    return { entries: contentfulEntries, asPath, isServer };
  };
};

const shopPage = {
  getShopPageData,
};

export default shopPage;
