/* eslint-disable no-else-return */
/* eslint-disable no-console */
/* eslint-disable no-return-await */
import fetchApi from '@/src/Middleware/fetchApi';

const getOrderDetail = ({ orderId, isDecathlon, ctx }) => {
  return async (dispatch) => {
    dispatch({ type: 'ORDER_TRACKING_LOADER', payload: true });
    const response = await fetchApi(ctx, `/api/customer/order`, {
      data: {
        orderId,
        isDecathlon,
      },
    });
    dispatch({ type: 'ORDER_TRACKING_LOADER', payload: false });
    if (response?.status) {
      dispatch({
        type: 'ORDER_TRACKING',
        payload: response?.data,
      });
    } else {
      dispatch({
        type: 'ORDER_TRACKING',
        payload: false,
      });
    }
    return response;
  };
};

const cartPage = {
  getOrderDetail,
};

export default cartPage;
